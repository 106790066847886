import React from 'react'
import {Link} from 'react-router-dom'

function Footer() {

    const date = new Date().getFullYear()

    const socialLinks = [
        {
            name: 'Instagram',
            url: 'https://www.instagram.com/john.ders/'
        },
        {
            name: 'LinkedIn',
            url: 'https://www.linkedin.com/in/jhsaunders/'
        }
    ]

    return(
        <footer id="footerSection">
            <div className="container-fluid-small">

                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 footer-title anim-bot">
                        <h2 className="big-title">Looking for a consultation?</h2>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 footer-project anim-bot">
                        <Link to='/contact'>Contact me</Link>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-11 col-xl-4 copyright">
                        <p>Copyright © {date} John Saunders.<br />Built with React.</p>
                        <div className="footer-menu">
                            <ul>
                                {socialLinks.map((item, i) => <li key={i}><a href={item.url}>{item.name}</a></li>)}
                            </ul>
                        </div>
                    </div>
                    <div className="col-1 footer-offset"></div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 contact-box contact-mail">
                        <h5 className="sub-title">Email</h5>
                        <ul>
                            <li><span>mail</span><span>@johnders.com</span></li>
                            <li><span>john</span><span>@powerdigital.com</span></li>
                        </ul>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 contact-box contact-address">
                        <h5 className="sub-title">Current Roles</h5>
                        <ul>
                            <li>Vice President of Product, nova</li>
                            <li>Mentor, Lavin Entrepreneurship SDSU</li>
                        </ul>
                    </div>
                </div>

            </div>
        </footer>
    )

}

export default Footer