import React from 'react'
import {Link} from 'react-router-dom'

function AboutText(){

    return(
        <section id="aboutStory" className="section">
            <div className="container-fluid-small">
                <div className="row">

                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 story-left anim-bot">
                        <h2 className="big-title">Transforming marketing insights into unique, automated product solutions.</h2>
                    </div>

                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 story-right anim-bot">
                        <div className="story">
                            <p className="big-p">As Vice President of Product at Power Digital, I lead our tech-enabled performance marketing company's most innovative initiatives, including the development of nova, our proprietary marketing intelligence platform. Nova provides businesses with precise growth analyses and actionable roadmaps, streamlining success across all marketing channels.<br /><br /> My role is pivotal in shaping the strategic vision and executing the data strategy that positions nova at the forefront of marketing technology.</p>
                        </div>
                    </div>

                    <div className="col-xl-3"></div>

                    <div className="col-xl-3"></div>

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 story-bottom anim-bot">
                        <ul>
                            <li>10+ Years of Leadership</li>
                            <li>1000s of Project Deployments</li>
                            <li>Industry Awards</li>
                        </ul>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3 story-bottom anim-bot">
                        <ul>
                            <li>Award-Winning Leadership</li>
                            <li>Expertise in Product</li>
                            <li>Advanced Data/AI Strategy</li>
                        </ul>
                    </div>

                </div>
            </div>
        </section>
    )
    
}

export default AboutText