import React from 'react'
import {Link} from 'react-router-dom'

function Logo(){

    return(
        <div className="logo">
            <Link to='/'><img alt="John Saunders" className="logo" src="/assets/images/logo.png" /></Link>
        </div>
    )
    
}

export default Logo