const Works = [
    {
        id: 1,
        name: 'Product Measurement + Impact',
        category: 'Data',
        fixedCategory: 'data',
        image: '/assets/images/1.jpg',
        background: '/assets/images/single-project-hero.jpg',
        headline: 'Enhancing Web Experiences Through Data',
        text: ['Leveraging tools like Quicksight, Mixpanel, and Google Analytics to provide actionable insights and improve application performance and business outcomes.'],
        date: '2015-now',
        servicesLeft: ['Quicksight', 'Mixpanel', 'Google Analytics', 'Database Schema'],
        servicesRight: ['Data Brainstorming', 'Market Research', 'Marketing Strategy Implementation'],
        imagesLeft: ['project-1-1.jpg', 'project-1-3.jpg'],
        imagesRight: ['project-1-2.jpg', 'project-1-4.jpg']
    },
    {   
        id: 2,
        name: 'Product Management and Strategy',
        category: 'product',
        fixedCategory: 'product',
        image: '/assets/images/4.jpg',
        background: '/assets/images/single-project-hero-4.jpg',
        headline: 'Strategic Leadership in Product Outcomes',
        text: ['Directing operations and strategic vision for Power Digital’s nova platform, utilizing agile methodologies to drive product excellence.'],
        date: '2017-now',
        servicesLeft: ['Product Development', 'Product Management', 'Agile Operations'],
        servicesRight: ['MVP Development', 'Product Research', 'Entrepreneurship'],
        imagesLeft: ['project-4-2.jpg'],
        imagesRight: ['project-4-1.jpg']
     },
     {
        id: 3,
        name: 'Team Management',
        category: 'management',
        fixedCategory: 'management',
        image: '/assets/images/3.jpg',
        background: '/assets/images/single-project-hero-3.jpg',
        headline: 'Empowering Teams and Leading Innovation',
        text: ['Managing diverse teams across multiple service offerings, driving career growth, and fostering a culture of innovation.'],
        date: '2015-now',
        servicesLeft: ['Team Development', 'Goal Setting', 'Recruitment'],
        servicesRight: ['Strategic Planning', 'Public Speaking', 'Financial Oversight'],
        imagesLeft: ['project-3-1.jpg'],
        imagesRight: ['project-3-2.jpg']
     },
     {
        id: 4,
        name: 'UX',
        category: 'UX',
        fixedCategory: 'ux',
        image: '/assets/images/2.jpg',
        background: '/assets/images/single-project-hero-2.jpg',
        headline: 'Creating Superior User Experiences',
        text: ['Designing and developing user-centric solutions for marketing clients and nova’s end-users, guided by strategic UX principles and data-driven insights.'],
        date: '2015-now',
        servicesLeft: ['Identity Design', 'Responsive UI/UX Design', 'Frontend Development'],
        servicesRight: ['UI Production', 'CRO Analysis', 'UX Research'],
        imagesLeft: ['project-2-1.jpg'],
        imagesRight: ['project-2-2.jpg']
     },
    // Uncomment and update this section if needed
    /*{
        id: 5,
        name: 'Information Security',
        category: 'security',
        fixedCategory: 'security',
        image: '/assets/images/5.jpg',
        background: '/assets/images/single-project-hero-5.jpg',
        headline: 'Navigating the Complex Landscape of Data Security',
        text: ['Overseeing security operations to ensure data integrity and compliance as Power Digital scales its operations.'],
        date: '2020-now',
        servicesLeft: ['Security Management', 'Data Privacy', 'Device Management'],
        servicesRight: ['Endpoint Protection', 'Malware Defense', 'Compliance and Policies'],
        imagesLeft: ['project-5-1.jpg'],
        imagesRight: ['project-5-2.jpg']
     },*/
]

export default Works;
