import React from 'react'

import TeamItem from '../elements/TeamItem'

function Team(){

    const team = [
        {
            name: 'John Saunders',
            image: 'assets/images/team1.jpg',
            job: 'VP of Product, nova',
            social: [
                {
                    name: 'Instagram',
                    url: 'https://www.instagram.com/john.ders/'
                },
                {
                    name: 'LinkedIn',
                    url: 'https://www.linkedin.com/in/jhsaunders/'
                }
            ]
        },
        {
            name: 'Baloo',
            image: 'assets/images/team2.jpg',
            job: 'Timekeeper of Dinner/Breakfast',
            social: []
        },
        {
            name: 'Roux',
            image: 'assets/images/team3.jpg',
            job: 'Resident Snoozer',
            social: []
        },
    ]
    const displayTeam = team.map((item, index) => <TeamItem key={index} image={item.image} name={item.name} job={item.job} social={item.social} />)

    return(
        <section id="aboutTeam">
            <div className="container-fluid-small">
                <div className="row team-row anim-bot-big">
                    {displayTeam}
                </div>
            </div>
        </section>
    )

}

export default Team