const ArticlesData = [
    {
        id: 1,
        date: 'March 2024',
        title: 'Navigating the Future with Agile Product Strategy',
        categories: ['Product Strategy', 'Innovation', 'Leadership'],
        image: '/assets/images/product-strategy.jpg',
        headline: 'Product Strategy for the Digital Age',
        textOne: [
            'In today’s fast-paced digital market, a flexible and agile product strategy is not just beneficial; it’s essential. Adapting quickly to market changes, consumer needs, and technological advances can define the success of a product.'],
        blockquote: {
            text: 'The key to successful product strategy is not just understanding where your product fits in the market today, but where it will stand tomorrow.',
            author: 'John Saunders'
        },
        textTwo: [
            'This article explores the principles of agile product development and strategic foresight in product planning, ensuring your products not only meet current demands but also adapt to future challenges and opportunities.'
        ],
        tags: ['product strategy', 'agile development', 'market adaptation']
    },
    {
        id: 2,
        date: 'April 2024',
        title: 'Integrating AI to Enhance Product and Marketing Performance',
        categories: ['AI', 'Product Development', 'Marketing'],
        image: '/assets/images/ai-integration.jpg',
        headline: 'Leveraging Artificial Intelligence',
        textOne: [
            'The integration of AI into product development and marketing strategies is revolutionizing how businesses interact with customers and manage operations. AI offers unprecedented capabilities in personalization, automation, and data analysis.'],
        blockquote: {
            text: 'AI is not just a tool; it’s a game changer for product innovation and customer engagement.',
            author: 'John Saunders'
        },
        textTwo: [
            'This article delves into case studies showcasing successful AI integrations that have enhanced user experience and operational efficiency, offering readers insights into harnessing AI’s potential.'
        ],
        tags: ['AI', 'product innovation', 'marketing automation']
    },
    {
        id: 3,
        date: 'May 2024',
        title: 'Launching Nova Intelligence: A New Era of Marketing Insights',
        categories: ['Product Launch', 'Nova Intelligence', 'Data Analytics'],
        image: '/assets/images/nova-launch.jpg',
        headline: 'The Dawn of Nova Intelligence',
        textOne: [
            'Nova Intelligence marks a significant milestone in marketing analytics, offering businesses a comprehensive platform to glean actionable insights from their data. This revolutionary product is designed to empower marketers by providing a granular understanding of consumer behavior and market trends.'],
        blockquote: {
            text: 'With Nova Intelligence, we’re not just making decisions; we’re crafting the future of marketing strategies.',
            author: 'John Saunders'
        },
        textTwo: [
            'Learn about the features, the inspiration behind Nova, and how it promises to transform the marketing landscape in this detailed introduction.'
        ],
        tags: ['nova intelligence', 'product launch', 'marketing insights']
    },
    {
        id: 4,
        date: 'June 2024',
        title: 'The Art of Leading Creative Teams',
        categories: ['Leadership', 'Team Management', 'Product Development'],
        image: '/assets/images/leadership.jpg',
        headline: 'Mastering Team Leadership',
        textOne: [
            'Effective leadership in product management goes beyond organizing tasks and timelines. It’s about inspiring, motivating, and harnessing the collective intelligence of diverse teams to foster innovation and achieve remarkable results.'],
        blockquote: {
            text: 'Great leaders don’t just direct; they empower.',
            author: 'John Saunders'
        },
        textTwo: [
            'This article provides insights into my approach to leadership, with practical tips for building cohesive teams and fostering an environment of creativity and accountability.'
        ],
        tags: ['leadership', 'team building', 'innovation']
    },
    {
        id: 5,
        date: 'July 2024',
        title: 'Human-Centered Design: Building Empathy into Product UX',
        categories: ['UX Design', 'Human-Centered Design', 'Product Development'],
        image: '/assets/images/human-centered-design.jpg',
        headline: 'Empathy in Design',
        textOne: [
            'Human-centered design is at the heart of creating engaging and effective user experiences. By focusing on the user’s needs and challenges, designers can create products that not only solve problems but also delight and engage users.'],
        blockquote: {
            text: 'Empathy is the foundation of human-centered design. Understanding the user’s journey is crucial to creating products that resonate.',
            author: 'John Saunders'
        },
        textTwo: [
            'Explore the principles and processes of human-centered design, with examples from my projects that illustrate how deep user understanding leads to successful product outcomes.'
        ],
        tags: ['UX design', 'empathy', 'user engagement']
    }
];

export default ArticlesData;
