import React, { useEffect} from 'react'
import {Link} from 'react-router-dom'
import {motion} from "framer-motion";

import Hero from '../components/Hero'
import Header from '../components/Header'
import AboutText from '../components/AboutText'
import Team from '../components/Team'
import Services from '../components/Services'
import Footer from '../components/Footer'

function About(){
    const LoadingTransition = { duration: 1, ease: [0.43, 0.13, 0.23, 0.96] };

    useEffect(() => {
        const headerAnim = document.querySelector('.header-anim')
        const heroTitle = document.querySelectorAll('.hero-title')
        
        setTimeout(() => {
            headerAnim.classList.add('header-anim-on')
        }, 1000)

        setTimeout(() => {
            heroTitle.forEach((item, index) => {
                setTimeout(() => {
                    item.classList.add('hero-title-on')
                }, 200 * index) 
            })
        }, 1500)
    }, [])

    const onScrollBottomAnim = () => {
        const animBot = document.querySelectorAll('.anim-bot')

        animBot.forEach(item => {
            let itemPosition = item.getBoundingClientRect();

            if(itemPosition.top >= 0 && itemPosition.top <= window.innerHeight - 250) {
                item.classList.add('item-anim-bot')
            }
        })   
    }

    useEffect(() => {
        window.addEventListener("scroll", onScrollBottomAnim);
        return () => window.removeEventListener("scroll", onScrollBottomAnim);
    }, [])

    return(
        <div>
            <motion.div 
                initial={{x:0}}
                animate={{x:'-100%'}}
                exit={{x:0}}
                transition={LoadingTransition}
                className="page-trans">
            </motion.div>
            <Header />
            <Hero>
                <h6 className="sub-title hero-title">Who I Am</h6>
                <h1 className="big-title hero-title">Recognized Top Tech Executive by the San Diego Business Journal, three years running.</h1>
            </Hero>
            <AboutText />
            <Team />
            <Services>
                <h2 className="big-title">Driving Digital Transformation with Strategic Product Leadership</h2>
                <p>My career is marked by a fervent commitment to excellence in digital product management and the strategic integration of technology for market leadership. Starting with a strong foundation in digital marketing, I have expanded my focus to encompass AI integration and strategic data utilization, significantly enhancing product functionality and business outcomes. My leadership is defined by my ability to foster innovation and predict market trends, guiding teams to exceed the conventional limits of tech and marketing.</p>
                <Link to='/contact'>Connect with Me</Link>
            </Services>
            <Footer />
        </div>

    )
    
}

export default About