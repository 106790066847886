import React from 'react';
import ServiceItem from '../elements/ServiceItem';

function Services(props) {
    const services = [
        {
            icon: 'eye-alt',
            name: 'UI/UX Design',
            list: [
                'User Experience Design',
                'Interaction Design',
                'Visual and Branding Solutions'
            ]
        },
        {
            icon: 'ui-keyboard',
            name: 'Product Management',
            list: [
                'End-to-End Website Management',
                'Application Development',
                'Agile & Lean Product Development'
            ]
        },
        {
            icon: 'chart-line',
            name: 'Data Strategy',
            list: [
                'Advanced Analytics',
                'Data Science and Insights',
                'Performance Measurement'
            ]
        },
        {
            icon: 'code-alt',
            name: 'Technical Expertise',
            list: [
                'React.js & Modern JavaScript',
                'PHP & Laravel',
                'Front-end Frameworks'
            ]
        }
    ];

    const displayServices = services.map((item, index) => (
        <ServiceItem key={index} icon={item.icon} title={item.name} list={item.list} />
    ));

    return (
        <section id="aboutServices" name="aboutServices">
            <div className="container-fluid-small">
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 services-top services-left anim-bot">
                        {props.children}
                    </div>
                    <div className="col-lg-1 col-xl-1"></div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                        <div className="row">
                            {displayServices}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
